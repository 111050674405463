import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import PaymentsMode from "./PaymentsMode/PaymentsMode";
import { Redirect } from 'react-router'
import { Translation } from 'react-i18next';

class Summary extends React.Component {
  render() {
    const { cart, freeze, timeSlotId, enableProductsReplacement, enableReplacement } = this.props;

    if (!timeSlotId) {
      return <Redirect to='/checkout' />;
    }

    return (
      <Translation>
        {t =>
          <div>
            <div style={{ marginBottom: "10px" }} className="signup-form-title">
              {t("checkout.summary.title")}
            </div>
            <div className="checkout-summary">
              <div className="container-fluid">
                <Row className="mb-3">
                  <Col className="px-0">
                    {t("subTotal")}
                  </Col>
                  <Col className="px-0 text-right checkout-summary-total-text">
                    {cart && `\u20AC ${cart.netTotal.toFixed(2)}`}
                  </Col>
                </Row>
                {cart && cart.fees && cart.fees.map(fee => (
                  <Row className="mb-3" key={fee.id}>
                    <div className="col-8 px-0">
                      {fee.description}
                    </div>
                    <div className="col-4 px-0 text-right checkout-summary-total-text">
                      {cart && `\u20AC ${fee.value.toFixed(2)}`}
                    </div>
                  </Row>
                ))}
                <Row className="pt-3 mb-3 checkout-summary-total">
                  <Col className="px-0 cart-menu-message">
                    {t("total")}*
                  </Col>
                  <Col className="px-0 text-right cart-menu-message">
                    {cart && cart.totalToPay ? `\u20AC ${cart.totalToPay.toFixed(2)}` : `\u20AC ${cart.netTotal.toFixed(2)}`}
                  </Col>
                </Row>
              </div>
            </div>
            {/* <div className="checkout-subtitle">
              *Si avvisa che, per ogni fardello di acqua aggiuntivo oltre ai 4, è previsto un supplemento di 1 euro per ogni unità aggiuntiva. 
              <br /> Numero massimo di fardelli ordinabili: 10.
            </div> */}
            <div className="checkout-subtitle">
              <span style={{ fontSize: "12px" }} className="primary-text">*Attenzione</span> <br />
              L'importo finale è suscettibile di modifiche dovute a variazioni di prezzo recepite dal sistema dopo la prenotazione online ma
              prima del ritiro della spesa. <br />
              Se hai acquistato articoli in promozione, il prezzo in offerta verrà applicato solo se la consegna della spesa avverrà nei giorni
              di validità dell'offerta stessa, altrimenti il prodotto verrà venduto a prezzo pieno.
              <br /> Al ritiro verranno addebitati gli shoppers necessari per il trasporto.
              <br /> Per ulteriori informazioni consulta le FAQ e i Termini e condizioni del servizio.
            </div>

            <hr />

            <span style={{ fontSize: "14px" }} className="title">
              POSSIAMO CHIAMARTI PER PROPORRE SOSTITUZIONI IN CASO DI INDISPONIBILITÀ' DI ALCUNE REFERENZE?
            </span>

            <div className="d-flex mt-3">
              <div className="radio-button mr-3">
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input"
                    id="isEnabledReplacement"
                    name="isEnabledReplacement"
                    value={true}
                    checked={enableProductsReplacement === true}
                    onChange={() => enableReplacement(true)}
                  />
                  <label style={{ fontFamily: "FuturaStd-Book" }}
                    className="custom-control-label" htmlFor="isEnabledReplacement">
                    Si
                </label>
                </div>
              </div>
              <div className="radio-button">
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input"
                    id="isDisableReplacement"
                    name="isDisableReplacement"
                    value={false}
                    checked={enableProductsReplacement === false}
                    onChange={() => enableReplacement(false)}
                  />
                  <label style={{ fontFamily: "FuturaStd-Book" }}
                    className="custom-control-label" htmlFor="isDisableReplacement">
                    No
                </label>
                </div>
              </div>
            </div>

            <hr />

            <PaymentsMode cart={cart} freeze={freeze} />

          </div>
        }
      </Translation>
    )
  }
}

export default Summary


